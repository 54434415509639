@import url('https://fonts.googleapis.com/css2?family=Roboto&family=Roboto+Slab:wght@400;700;900&display=swap');

:root {
  --green: #cef988;
  --pale-green: #b0c88a;
}

.visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  background: #181818;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: 'Roboto Slab', serif;
  color: white;
}

h2,
h3 {
  color: var(--green);
  font-size: 4rem;
  font-weight: 700;
  text-align: center;
  font-family: 'Roboto Slab', serif;
}

p {
  font-family: 'Roboto', sans-serif;
  color: white;
}

a {
  font-family: 'Roboto Slab', serif;
  text-decoration: none;
  padding: 0 2px;
}

.green {
  color: var(--green);
}
